import { formatValue } from 'react-currency-input-field';

// const IsNotNull = (s) => {
//   return s !== null && s !== undefined ? true : false
// }

const IsNotNull = (s) => {
  return s ? true : false
}

const patternCheck = (v, regexp) => {
  return regexp.test(v);
}

const str2ab=(str)=> {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

const getFormattedPrice = (price, symbol, group, decimal) => {
  let rs = formatValue({
    value: price.toString(),
    groupSeparator: group,
    decimalSeparator: decimal,
    prefix: symbol,
  });
  return rs;
}

const getFormattedNumber = (num, group, decimal) => {
  let rs = formatValue({
    value: num.toString(),
    groupSeparator: group,
    decimalSeparator: decimal
  });
  return rs;
}

/*
  troot: this
  ph: placeholder
  st_opt: state name
  f1: db col id
  f2: db col name
  cmb: combine t/f
  c1: db col
  c2: db col
*/
const MappingOptions = (troot, data, ph, st_opt, f1, f2, cmb, c1, c2) => {
  if (IsNotNull(data)) {
    let g = [];
    data.map(x => { g.push({ label: IsNotNull(cmb) ? cmb ? `${x[c1]} - ${x[c2]}` : x[f2] : x[f2], value: x[f1], o: x }) })
    const options = [{ label: ph, options: g }]
    troot.setState({ [st_opt]: options })
  }
}

/*
    Untuk cek sebelum submit
    troot: this
    rs: result var
    st1: state
    ss: state style
    erm: error message
    type: tipe input text or select (v/s)
  */

const checkValue = (troot, rs, st1, ss, erm, type, tnotif) => {
  if (st1 === "" || st1 === null) {
    rs = false;
    if (type === "v") {
      troot.setState({
        [ss]: "form-control-err"
      })
    } else if (type === "s") {
      troot.setState({
        [ss]: "form-select-err"
      })
    }
  }
  if (troot.state[ss].includes("err")) {
    rs = false;
  }
  return rs
}

const changeID = (arr) => {
  arr.map((x, i) => {
    x.id = i + 1
  })
}

/*
  troot: this
  find_value: find value
  st_opt: state options
  st: state name to set
*/
const FindSetOption = (troot, find_value, st_opt, st) => {
  let s = troot.state[st];
  let rs = IsNotNull(find_value) ? st_opt.options.find(option => option.value === find_value) : { label: "", value: null, o: null }
  s.label = rs.label;
  s.value = rs.value;
  s.o = rs.o
  troot.setState({ [st]: s })
}

/*
  value: this
  has_desc: has decimal
  dec: max decimal
*/
const toFloat = (value, has_desc, dec) => {
  let tmp_val = IsNotNull(value) ? value : 0
  let rs = 0
  rs = parseFloat(has_desc ? parseFloat(tmp_val.toString().replace(/,/g, '.')).toFixed(dec) : tmp_val.toString().replace(/,/g, '.'))
  return rs
}

/*
for check isnotnull or return default
  value: check value
  def: default value
*/
const INNRD = (value, def) => {
  return IsNotNull(value) ? value : def
}

export {
  IsNotNull,
  patternCheck,
  checkValue,
  getFormattedPrice,
  getFormattedNumber,
  MappingOptions,
  changeID,
  FindSetOption,
  toFloat,
  INNRD,
  str2ab
}