import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next";
import styles from "../../assets/module/common/common.module.css"

const RolesController = require('../../controllers/admins/roles')

const SidebarContent = props => {
  const ref = useRef();

  function getRoles() {
    return RolesController.getRoles()
  }

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li className={`${['AD', 'AD_A', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-blackboard"></i>
                <span>{props.t("Duty Task")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/duty-act">{props.t("Active")} </Link>
                </li>
                <li>
                  <Link to="/duty">{props.t("Complete")} </Link>
                </li>
              </ul>
            </li>
            <li className={`${['AD', 'HRD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/emp" className=" waves-effect">
                <i className="ti-user"></i>
                <span>{props.t("Employees")}</span>
              </Link>
            </li>
            <li className={`${['AD', 'AD_A', 'AD_F', 'FIN', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}
              id="acc-menu">
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-bag"></i>
                <span>{props.t("Accounting")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false" id="acc-submenu">
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/cash-bank" >{props.t("Cash & Bank")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/sell">{props.t("Sales")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/buy">{props.t("Purchase")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/expense">{props.t("Expense")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/journals">{props.t("Journals")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/tb">{props.t("Trial Balance")}</Link>
                </li>
                <li className={`${['AD', 'AD_A', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/reimbursements">{props.t("Reimbursements")}</Link>
                </li>
              </ul>
            </li>
            {/* <li className={`${['AD', 'AD_A', 'AD_F', 'FIN', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-money"></i>
                <span>{props.t("Payments")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD', 'AD_A', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/payments">{props.t("Allowance")}</Link>
                </li>
                <li className={`${['AD', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/os-payments">{props.t("Outstanding")}</Link>
                </li>
                <li className={`${['AD', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/rm-payments">{props.t("Reimbursement")}</Link>
                </li>
              </ul>
            </li> */}
            <li className={`${['AD', 'AD_WH','AD_STR','STR', 'WH'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Warehouse")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD', 'AD_WH','AD_STR','STR', 'WH'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/warehouses">{props.t("Warehouse")}</Link>
                </li>
                <li className={`${['AD', 'AD_WH','AD_STR','STR', 'WH'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/pi">{props.t("Products & Items")}</Link>
                </li>
                
              </ul>
            </li>
            <li className={`${['AD', 'AD_A', 'AD_F', 'FIN', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-receipt"></i>
                <span>{props.t("Bills")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD', 'AD_A', 'ALW'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/bills">{props.t("Allowance")}</Link>
                </li>
                <li className={`${['AD', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/os">{props.t("Outstanding")}</Link>
                </li>
                <li className={`${['AD', 'AD_F', 'FIN'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/rm">{props.t("Reimbursement")}</Link>
                </li>
              </ul>
            </li>
            <li className={`${['AD', 'AD_A', 'AD_F', 'AD_STR'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-settings"></i>
                <span>{props.t("Master")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/aircrafts">{props.t("Aircrafts")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/accounting">{props.t("Accounting")}</Link>
                </li>
                <li className={`${['AD', 'AD_A'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-locations">{props.t("Locations")}</Link>
                </li>
                <li className={`${['AD', 'AD_F', 'HRD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-job">{props.t("Job")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-bank">{props.t("Bank Account")}</Link>
                </li>
                {/* <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-list-bank">{props.t("List Bank for Options")}</Link>
                </li> */}
                {/* <li className={`${['AD', 'AD_A'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-sch-time">{props.t("Schedules Times")}</Link>
                </li> */}
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-curr">{props.t("Currency")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/vendors">{props.t("Vendor Data")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/customers">{props.t("Customers Data")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/tax">{props.t("Tax Data")}</Link>
                </li>
                {/* <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-monthly-os">{props.t("Monthly OS")}</Link>
                </li> */}
                {/* <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-tt">{props.t("Transaction Types")}</Link>
                </li> */}
                <li className={`${['AD', 'AD_STR'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/st-building">{props.t("Building")}</Link>
                </li>
              </ul>
            </li>
            <li className={`${['AD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-star"></i>
                <span>{props.t("Admin")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/user-manage">{props.t("Users")}</Link>
                </li>
              </ul>
            </li>
            {/* <li className={`${['AD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-star"></i>
                <span>{props.t("Bar")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/bar">{props.t("Bar")}</Link>
                </li>
              </ul>
            </li> */}
            <li className={`${['AD', 'AD_A', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-file"></i>
                <span>{props.t("Report")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={`${['AD', 'AD_A'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/report-allowance">{props.t("Allowance")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/report-os">{props.t("OS")}</Link>
                </li>
                <li className={`${['AD', 'AD_F'].filter(element => getRoles().includes(element)).length > 0 ? "" : styles['hide-el']} `}>
                  <Link to="/report-acc">{props.t("Accounting")}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
