import { lazy } from "react"
import { Redirect } from "react-router-dom"

//Gantt
const task_active = lazy(() => import("../pages/Task/task_active"));
const task_complete = lazy(() => import("../pages/Task/task_complete"));

//Settings
const SetLocations = lazy(() => import("../pages/Settings/SetLocations"));
const SetJobs = lazy(() => import("../pages/Settings/SetJobs"));
const SetBank = lazy(() => import("../pages/Settings/SetBank"));
const SetSchTime = lazy(() => import("../pages/Settings/SetSchTime"));
const SetCurrency = lazy(() => import("../pages/Settings/SetCurrency"));
const SetMonthlyOS = lazy(() => import("../pages/Settings/SetMonthlyOS"));
const SetTT = lazy(() => import("../pages/Settings/SetTransactionTypes"));
const SetListBank = lazy(() => import("../pages/Settings/SetListBank"));
const SetBuilding = lazy(() => import("../pages/Settings/Building/SetBuilding"));
const SetAircrafts = lazy(() => import("../pages/Settings/Aircrafts/SetAircrafts"));

//Transactions
const Payments = lazy(() => import("../pages/Transactions/Payments"));
const Bills = lazy(() => import("../pages/Transactions/Bills"));
const ReimbursementBills = lazy(() => import("../pages/Reimbursement/reimbursement"));
const ReimbursementPayments = lazy(() => import("../pages/Reimbursement/rm_payments"));

//accounting
const Journal = lazy(() => import("../pages/Accounting/journal/trial-balance"));

//Outstanding
const Outstanding = lazy(() => import("../pages/Outstanding/outstanding"));
const OsPayments = lazy(() => import("../pages/Outstanding/os_payments"));
const Vendors = lazy(() => import("../pages/Vendors/SetVendors"));
const Customers = lazy(() => import("../pages/Settings/customers/SetCustomers"));
const Tax = lazy(() => import("../pages/Settings/SetTax"));

//Accounting
const Accounting = lazy(() => import("../pages/Settings/accounting/SetAccounting"));
const CashBank = lazy(() => import("../pages/Accounting/cash-bank/cash-bank-dashboard"));
const AccountCB = lazy(() => import("../pages/Accounting/cash-bank/account-dashboard/account-dashboard"));
const AccountJr = lazy(() => import("../pages/Accounting/journal/journal-sub-dashboard"));
const Expense = lazy(() => import("../pages/Accounting/expense/dashboard-expense"));
const ReimbursementNew = lazy(() => import("../pages/Accounting/reimbursement/dashboard-rm"));
const TrialBalance = lazy(() => import("../pages/Accounting/journal/trial-balance"));
const Journals = lazy(() => import("../pages/Accounting/journal/journal-dashboard"));
// const SubJournals = lazy(() => import("../pages/Accounting/journal/journal-sub-dashboard"));
const BLDash = lazy(() => import("../pages/Accounting/cash-bank/bank-loan/bank-loan-sub-dashboard"));


//Employees
const Employees = lazy(() => import("../pages/Employees/Employees"));

//Admin
const AdminPages = lazy(() => import("../pages/Admin/UserManager"));

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));

// Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard/dashboard"));

//Report
const ReportOS = lazy(() => import("../pages/Report/report_os"));
const ReportAcc = lazy(() => import("../pages/Report/accounting/acc-dashboard"));

//warehouse
const Warehouses = lazy(() => import("../pages/Warehouse/w-dashboard"));
const PI = lazy(() => import("../pages/Warehouse/products_items/products-items-dashboard"));

//Bar
// const BarDash = lazy(() => import("../pages/Bar/dashboard-bar"));

const userRoutes = [
  { path: "/dashboard", component: Dashboard, roles: ['USR'] },

  //Schedule Gantt Chart
  { path: "/duty-act", component: task_active, roles: ['AD', 'AD_A', 'ALW'] },
  { path: "/duty", component: task_complete, roles: ['AD', 'AD_A', 'ALW'] },

  //Admin
  { path: "/user-manage", component: AdminPages, roles: ['AD'] },

  //Employees
  { path: "/emp", component: Employees, roles: ['AD', 'HRD', 'AD_F'] },

  //Settings
  { path: "/st-locations", component: SetLocations, roles: ['AD', 'AD_A'] },
  { path: "/st-sch-time", component: SetSchTime, roles: ['AD', 'AD_A'] },
  { path: "/st-job", component: SetJobs, roles: ['AD', 'HRD', 'AD_F'] },
  { path: "/st-bank", component: SetBank, roles: ['AD', 'AD_F'] },
  { path: "/st-curr", component: SetCurrency, roles: ['AD', 'AD_F', 'AD_A'] },
  { path: "/st-monthly-os", component: SetMonthlyOS, roles: ['AD', 'AD_F'] },
  { path: "/st-tt", component: SetTT, roles: ['AD', 'AD_F'] },
  { path: "/vendors", component: Vendors, roles: ['AD', 'AD_F'] },
  { path: "/customers", component: Customers, roles: ['AD', 'AD_F'] },
  { path: "/tax", component: Tax, roles: ['AD', 'AD_F'] },
  { path: "/accounting", component: Accounting, roles: ['AD', 'AD_F'] },
  { path: "/st-list-bank", component: SetListBank, roles: ['AD', 'AD_F'] },
  { path: "/st-building", component: SetBuilding, roles: ['AD','AD_STR'] },
  { path: "/aircrafts", component: SetAircrafts, roles: ['AD'] },
  

  //Accounting
  { path: "/cash-bank", component: CashBank, roles: ['AD', 'AD_F'] },
  { path: "/expense", component: Expense, roles: ['AD', 'AD_F'] },
  { path: "/reimbursements", component: ReimbursementNew, roles: ['AD', 'AD_F'] },
  { path: "/tb", component: TrialBalance, roles: ['AD', 'AD_F'] },
  { path: "/account-cb", component: AccountCB, roles: ['AD', 'AD_F'] },
  { path: "/account-jr", component: AccountJr, roles: ['AD', 'AD_F'] },
  { path: "/bl-dash", component: BLDash, roles: ['AD', 'AD_F'] },
  { path: "/journals", component: Journals, roles: ['AD', 'AD_F'] },
  // { path: "/sub-journals", component: SubJournals, roles: ['AD', 'AD_F'] },

  //Payment Menu
  { path: "/payments", component: Payments, roles: ['AD', 'AD_A', 'ALW'] },
  { path: "/bills", component: Bills, roles: ['AD', 'AD_A', 'ALW'] },

  { path: "/rm", component: ReimbursementBills, roles: ['AD', 'AD_F', 'FIN'] },
  { path: "/rm-payments", component: ReimbursementPayments, roles: ['AD', 'AD_F', 'FIN'] },
  { path: "/os-payments", component: OsPayments, roles: ['AD', 'AD_F', 'FIN'] },

  //outstanding
  { path: "/os", component: Outstanding, roles: ['AD', 'AD_F', 'FIN'] },



  //report
  { path: "/report-os", component: ReportOS, roles: ['AD', 'AD_F', 'FIN'] },
  { path: "/report-acc", component: ReportAcc, roles: ['AD', 'AD_F', 'FIN'] },

  //warehouse

  { path: "/warehouses", component: Warehouses, roles: ['AD', 'AD_WH','AD_STR','STR','WH'] },
  { path: "/pi", component: PI, roles: ['AD', 'AD_WH','AD_STR','STR','WH'] },

  // { path: "/bar", component: BarDash, roles: ['AD'] },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/*", exact: true, component: () => <Redirect to="/login" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
